import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AlertController, IonSlides } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { reduce } from 'rxjs/operators';
import { PreloadWizardInterface } from 'src/app/interfaces/preload-wizard.interface';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { PreloadWizardService } from 'src/app/services/preload-wizard/preload-wizard.service';
import { UtmService } from 'src/app/services/utm/utm.service';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements OnInit, OnDestroy {
  @ViewChild(IonSlides) slides: IonSlides;
  phoneStartsWith0 = true;

  wizar: any[] = [];
  calculatorForm: FormGroup;
  backgroundColorSpep1: string = 'var(--azul-1)';
  backgroundColorSpep2: string = 'var(--azul-0)';
  backgroundColorSpep3: string = 'var(--azul-0)';
  lineClass1 = 'line';
  lineClass2 = 'line';
  nextButton = 'Siguiente';
  backButton = false;
  showForwardIcon = true;
  timer = 8;
  showTimer: boolean;

  preloadInfo: PreloadWizardInterface;
  preloadWizardSubscription: Subscription;
  gclid: string;

  constructor(
    private leadService: LeadsService,
    private fb: FormBuilder,
    private alertController: AlertController,
    private route: ActivatedRoute,
    private preloadWizardService: PreloadWizardService,
    public utmService: UtmService
  ) {
    this.gclid = this.utmService.gclid;
    console.log('GCLIDDDD->', this.gclid);
  }

  ngOnInit() {
    this.preloadWizardSubscription =
      this.preloadWizardService.preLoad$.subscribe((preLoadInfo) => {
        this.preloadInfo = preLoadInfo;
        if (this.preloadInfo) {
          this.buildForm();
          this.loadWizard();

          setTimeout(() => {
            this.slides.slideTo(2);
          }, 1000);
        } else {
          this.buildForm();
          this.loadWizard();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.preloadWizardSubscription)
      this.preloadWizardSubscription.unsubscribe();
  }

  get phoneFormat() {
    const phone = this.calculatorForm.get('phone').value;
    if (phone && phone.substring(0, 1) == 0) {
      this.calculatorForm.controls['phone'].setErrors({ incorrect: true });
      return 'Ingresar el codigo de area sin 0';
    }
    if (phone && phone.substring(0, 4) == 1115) {
      this.calculatorForm.controls['phone'].setErrors({ incorrect: true });
      return 'No ingresar 15 luego del codigo de area';
    }
    return '';
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: message,
      buttons: ['Ok'],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }

  buildForm() {
    if (this.preloadInfo !== null) {
      this.calculatorForm = this.fb.group({
        propertyType: [this.preloadInfo.type],
        firstMonth: [this.preloadInfo.price],
        expenses: [this.preloadInfo.expenses],
        name: [''],
        phone: [
          '',
          [
            Validators.minLength(10),
            Validators.required,
            Validators.maxLength(15),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        email: [''],
      });
    } else {
      this.calculatorForm = this.fb.group({
        propertyType: [''],
        firstMonth: [''],
        expenses: [''],
        name: [''],
        phone: [
          '',
          [
            Validators.minLength(10),
            Validators.required,
            Validators.maxLength(15),
            Validators.pattern('^[0-9]*$'),
          ],
        ],
        email: [''],
      });
    }
  }

  loadWizard(): void {
    this.wizar.push({
      step: '1',
      stepClass: 'currentStep',
      stepColor: 'gris3',
      visited: true,
      name: 'Tipo de propiedad',
      value: ['Vivienda'],
    });
    this.wizar.push({
      step: '2',
      stepClass: 'futureStep',
      stepColor: 'gris4',
      visited: false,
      name: 'Valor de alquiler',
      value: [' .'],
    });
    this.wizar.push({
      step: '3',
      stepClass: 'futureStep',
      stepColor: 'gris2',
      visited: false,
      name: 'Contacto',
      value: [' .'],
    });
  }

  // activateTimer() {
  //   let time = 0;
  //   for (let i = 8; i > -1; i--) {
  //     setTimeout(() => {
  //       if (i === 0) {
  //         this.showTimer = false;
  //         this.nextStep();
  //         window.open('https://wa.me/5491130350918', '_blank');
  //       }
  //       this.timer = i;
  //     }, time);
  //     time = time + 1000;
  //   }
  // }

  async nextStep() {
    let index = await this.getCurrentSlideIndex();
    console.log('nextStep', index);
    if (index === 2) {
      if (
        this.calculatorForm.get('phone').valid &&
        this.name &&
        String(this.calculatorForm.get('phone').value).substring(0, 1) !== '15'
      ) {
        this.showTimer = true;
        // this.activateTimer();
        console.log('Activo Timer');
      }
    }
    if (index === 3) {
      this.calculatorForm.reset();
      this.slides.slideTo(0);
      this.backgroundStepCircle(0);
      console.log('Vuelvo al origen');
    } else {
      console.log('nextSlide, acá tendria que sumar 1?');
      this.nextSlide(index);
    }
  }

  nextSlide(page: number) {
    switch (page) {
      case 0:
        console.log('nextSlide', page);
        if (this.propertyType) {
          this.slides.slideNext();
          this.backgroundStepCircle(page + 1);
        } else this.managerMessage(page);
        break;
      case 1:
        console.log('nextSlide', page);
        if (
          (this.expenses && this.firstMonth) ||
          (this.expenses === 0 && this.firstMonth)
        ) {
          this.slides.slideNext();
          this.backgroundStepCircle(page + 1);
        } else this.managerMessage(page);
        break;
      case 2:
        console.log('nextSlide', page);
        if (
          this.calculatorForm.get('phone').valid &&
          this.name &&
          String(this.calculatorForm.get('phone').value).substring(0, 1) !==
            '15'
        ) {
          this.slides.slideNext();
          this.backgroundStepCircle(page + 1);
        } else this.managerMessage(page);
        break;
      case 3:
        break;
    }
  }

  managerMessage(page: number) {
    switch (page) {
      case 0:
        this.presentAlert(
          'Por favor seleccione el uso de que le va a dar a la propiedad: para vivienda o comercio'
        );
        break;
      case 1:
        if (!this.expenses && this.firstMonth) {
          this.presentAlert(
            'Por favor ingrese el valor de las expensas, si el departamento no paga expensas ingrese 0'
          );
        }
        if (this.expenses && !this.firstMonth) {
          this.presentAlert('Por favor ingrese el valor del alquiler');
        }
        if (!this.expenses && !this.firstMonth) {
          this.presentAlert(
            'Por favor ingrese el valor del alquiler y de las expensas,  si el departamento no paga expensas ingrese 0'
          );
        }

        break;
      case 2:
        console.log(
          "this.calculatorForm.get('phone').valid",
          this.calculatorForm.get('phone').valid
        );
        if (!this.name && this.phone) {
          this.presentAlert('Por favor ingrese su nombre');
        }
        if (this.name && !this.calculatorForm.get('phone').valid) {
          this.presentAlert(
            'Por favor ingrese su número de teléfono para poder contactarlo en breve. Ingrese su código de área + nro. de teléfono. No utilice el 15 al inicio.'
          );
        }
        if (!this.phone && !this.name) {
          this.presentAlert(
            'Por favor ingrese su nombre y el número teléfono para poder contactarlo en breve'
          );
        }
        break;
    }
  }

  async getCurrentSlideIndex() {
    const index = this.slides.getActiveIndex();
    console.log('getCurrentSlideIndex', index);
    return await index;
  }

  async backStep() {
    console.log('backStep');
    let index = await this.getCurrentSlideIndex();
    this.backgroundStepCircle(index - 1);
    this.slides.slidePrev();
  }

  get propertyType() {
    return this.calculatorForm.get('propertyType').value;
  }
  get firstMonth() {
    return this.calculatorForm.get('firstMonth').value;
  }
  get expenses() {
    return this.calculatorForm.get('expenses').value;
  }
  get name() {
    return this.calculatorForm.get('name').value;
  }
  get email() {
    return this.calculatorForm.get('email').value;
  }
  get phone() {
    return this.calculatorForm.get('phone').value;
  }

  backgroundStepCircle(index: number) {
    switch (index) {
      case 0:
        this.backgroundColorSpep1 = 'var(--azul-1)';
        this.backgroundColorSpep2 = 'var(--azul-0)';
        this.backgroundColorSpep3 = 'var(--azul-0)';
        this.lineClass1 = 'line';
        this.lineClass2 = 'line';
        this.nextButton = 'Siguiente';
        this.backButton = true;
        this.showForwardIcon = true;
        break;
      case 1:
        this.backgroundColorSpep1 = 'var(--gris-3)';
        this.backgroundColorSpep2 = 'var(--azul-1)';
        this.backgroundColorSpep3 = 'var(--azul-0)';
        this.lineClass1 = 'boldLine';
        this.lineClass2 = 'line';
        this.nextButton = 'Siguiente';
        this.backButton = false;
        this.showForwardIcon = true;
        break;
      case 2:
        this.backgroundColorSpep1 = 'var(--gris-3)';
        this.backgroundColorSpep2 = 'var(--gris-3)';
        this.backgroundColorSpep3 = 'var(--azul-1)';
        this.lineClass1 = 'boldLine';
        this.lineClass2 = 'boldLine';
        this.nextButton = 'Enviar';
        this.backButton = false;
        this.showForwardIcon = true;
        break;
      case 3:
        const data = this.calculatorForm.value;
        if (data.phone || data.name)
          this.leadService.leadAdd(
            data.phone,
            data.email,
            data.propertyType,
            data.firstMonth,
            data.expenses,
            data.name,
            this.utmService.utm_source ? this.utmService.utm_source : '',
            this.utmService.utm_medium ? this.utmService.utm_medium : '',
            this.utmService.utm_campaign ? this.utmService.utm_campaign : '',
            this.utmService.utm_term ? this.utmService.utm_term : '',
            this.utmService.utm_content ? this.utmService.utm_content : '',
            this.gclid ? this.gclid : ''
          );
        this.backgroundColorSpep1 = 'var(--gris-3)';
        this.backgroundColorSpep2 = 'var(--gris-3)';
        this.backgroundColorSpep3 = 'var(--gris-3)';
        this.lineClass1 = 'boldLine';
        this.lineClass2 = 'boldLine';
        this.nextButton = '¡Gracias!';
        this.backButton = false;
        this.showForwardIcon = false;
        break;
    }
  }

  line1Color(index: number) {
    switch (index) {
      case 0:
        this.lineClass1 = 'line';
        break;
      case 1:
        this.lineClass1 = 'boldLine';
        break;
      case 2:
        this.lineClass1 = 'boldLine';
        break;
      case 3:
        this.lineClass1 = 'boldLine';
        break;
    }
  }

  line2Color(index: number) {
    switch (index) {
      case 0:
        this.lineClass2 = 'line';
        break;
      case 1:
        this.lineClass2 = 'line';
        break;
      case 2:
        this.lineClass2 = 'boldLine';
        break;
      case 3:
        this.lineClass2 = 'boldLine';
        break;
    }
  }
}
