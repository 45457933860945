import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'propiedades',
    loadChildren: () =>
      import('./pages/properties/properties.module').then(
        (m) => m.PropertiesPageModule
      ),
  },
  {
    path: 'inquilinos',
    loadChildren: () =>
      import('./pages/tenants/tenants.module').then((m) => m.TenantsPageModule),
  },
  {
    path: 'propietarios',
    loadChildren: () =>
      import('./pages/owners/owners.module').then((m) => m.OwnersPageModule),
  },
  {
    path: 'inmobiliarias',
    loadChildren: () =>
      import('./pages/brokers/brokers.module').then((m) => m.BrokersPageModule),
  },
  {
    path: 'detalle-propiedad/:id',
    loadChildren: () =>
      import('./pages/propertie-detail/propertie-detail.module').then(
        (m) => m.PropertieDetailPageModule
      ),
  },
  {
    path: 'propertie-detail',
    loadChildren: () =>
      import('./pages/propertie-detail/propertie-detail.module').then(
        (m) => m.PropertieDetailPageModule
      ),
  },
  {
    path: 'propertie-detail',
    loadChildren: () =>
      import('./pages/propertie-detail/propertie-detail.module').then(
        (m) => m.PropertieDetailPageModule
      ),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
