import { AfterViewInit, Component } from '@angular/core';

declare const google: any;

@Component({
  selector: 'app-google-maps-reviews',
  templateUrl: './google-maps-reviews.component.html',
  styleUrls: ['./google-maps-reviews.component.scss'],
})
export class GoogleMapsReviewsComponent implements AfterViewInit {
  service;
  public reviews = [];

  constructor() {}

  ngAfterViewInit() {
    const request = {
      placeId: 'ChIJA20N4C21vJURo7zd_UokBrU',
      fields: ['reviews'],
      language: 'es-419',
    };
    this.service = new google.maps.places.PlacesService(
      document.getElementById('googleReviews')
    );

    this.service.getDetails(request, this.callback);
  }

  public callback = (place, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      console.log('place.reviews', place.reviews);
      this.reviews = place.reviews.filter(
        (review: any) => review.rating >= 4 && review.rating <= 5
      );
    }
  };

  createRange(number) {
    const items: number[] = [];
    for (let i = 1; i <= number; i++) {
      items.push(i);
    }
    return items;
  }

  slideOpts = {
    slidesPerView: 4,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      reverseDirection: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      530: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      840: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },
  };
}
