export const environment = {
  production: true,
  config: {
    apiKey: 'AIzaSyA-e2M73ST-DHhd-gGNmT28mlJMmI5IRqY',
    authDomain: 'garantiaya.firebaseapp.com',
    databaseURL: 'https://garantiaya.firebaseio.com',
    projectId: 'garantiaya',
    storageBucket: 'garantiaya.appspot.com',
    messagingSenderId: '456933274058',
    appId: '1:456933274058:web:b097420f51baae79',
    measurementId: 'G-CW5V71MV6Y',
  },
};
