import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterInterface } from 'src/app/interfaces/filter.interface';
import { PricesInterface } from 'src/app/interfaces/prices.interfaces';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-prices-filter',
  templateUrl: './prices-filter.component.html',
  styleUrls: ['./prices-filter.component.scss'],
})
export class PricesFilterComponent implements OnInit {
  priceA: number;
  priceB: number;

  expensesA: number;
  expensesB: number;

  priceExpensesA: number;
  priceExpensesB: number;

  constructor(public propertiesService: PropertiesService) {}

  ngOnInit() {
    this.propertiesService.priceA$.subscribe(
      res => this.priceA = res
    )
    this.propertiesService.priceB$.subscribe(
      res => this.priceB = res
    )
    this.propertiesService.expensesA$.subscribe(
      res => this.expensesA = res
    )
    this.propertiesService.expensesB$.subscribe(
      res => this.expensesB = res
    )
    this.propertiesService.priceExpensesA$.subscribe(
      res => this.priceExpensesA = res
    )
    this.propertiesService.priceExpensesB$.subscribe(
      res => this.priceExpensesB = res
    )
  }

  getFilterPrice() {
    this.propertiesService.priceA$.next(this.priceA);
    this.propertiesService.priceB$.next(this.priceB);
  }

  getFilterExpenses() {
    this.propertiesService.expensesA$.next(this.expensesA);
    this.propertiesService.expensesB$.next(this.expensesB);
  }

  getFilterPriceExpenses() {
    this.propertiesService.priceExpensesA$.next(this.priceExpensesA);
    this.propertiesService.priceExpensesB$.next(this.priceExpensesB);
  }
}
