import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Platform } from '@ionic/angular';
import { PropertiesService } from 'src/app/services/properties/properties.service';

export interface ItemSelectPrice {
  priceA: number;
  priceB: number;
  label: string;
}
@Component({
  selector: 'app-properties',
  templateUrl: './properties.component.html',
  styleUrls: ['./properties.component.scss'],
})
export class PropertiesComponent implements OnInit {
  filterForm: FormGroup;
  cities: { name: string }[] = [];
  intervalPrice: ItemSelectPrice[] = [{ priceA: 0, priceB: 0, label: '' }];
  disableCity = true;

  constructor(
    private platform: Platform,
    public propertiesService: PropertiesService
  ) {
    this.propertiesService.limit$.next(3);
  }

  interface() {
    if (this.platform.is('desktop')) {
      return 'popover';
    } else {
      return 'action-sheet';
    }
  }

  ngOnInit() {
    this.buildFilterForm();
    this.loadPriceInterval();

    this.filterForm.get('price').valueChanges.subscribe((item) => {
      // this.filterForm.get('priceA').setValue(item.priceA);
      // this.filterForm.get('priceB').setValue(item.priceB);
      this.propertiesService.priceA$.next(item.priceA);
      this.propertiesService.priceB$.next(item.priceB);
    });

    this.filterForm.get('province').valueChanges.subscribe((item) => {
      this.propertiesService.province$.next(item.name);
      this.filterForm.get('city').reset();
      this.disableCity = false;
      this.cities = item.locations;
    });

    this.filterForm.get('city').valueChanges.subscribe((item) => {
      let a = [];
      if (item) a.push(item);
      console.log('aCity', a);
      this.propertiesService.cities$.next(a);
    });
  }

  loadPriceInterval() {
    this.intervalPrice[0] = {
      priceA: 0,
      priceB: 20000,
      label: 'Hasta $20.000',
    };
    this.intervalPrice.push({
      priceA: 20000,
      priceB: 25000,
      label: 'desde $20.000 hasta $25.000',
    });
    this.intervalPrice.push({
      priceA: 25000,
      priceB: 30000,
      label: 'desde $25.000 hasta $30.000',
    });
    this.intervalPrice.push({
      priceA: 30000,
      priceB: 40000,
      label: 'desde $30.000 hasta $40.000',
    });
    this.intervalPrice.push({
      priceA: 40000,
      priceB: null,
      label: 'más de $40.000',
    });
  }

  buildFilterForm() {
    this.filterForm = new FormGroup({
      province: new FormControl(''),
      city: new FormControl(''),
      price: new FormControl(''),
      priceA: new FormControl(''),
      priceB: new FormControl(''),
    });
  }
}
