import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterInterface } from 'src/app/interfaces/filter.interface';
import {
  GroupButtonsInterface,
  ItemChipButtonInterface,
} from 'src/app/interfaces/group-buttons.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-rooms-filter',
  templateUrl: './rooms-filter.component.html',
  styleUrls: ['./rooms-filter.component.scss'],
})
export class RoomsFilterComponent implements OnInit {
  buttonsRoom: GroupButtonsInterface = { title: '', buttonItems: [] };
  filter: FilterInterface;

  filterSubcription: Subscription;

  constructor(public propertiesService: PropertiesService) {}

  ngOnInit() {
    this.loadGroupButtonsRoom();
  }

  loadGroupButtonsRoom() {
    this.buttonsRoom.title = 'Ambientes';
    this.buttonsRoom.buttonItems.push({ name: '1', value: 1, state: false });
    this.buttonsRoom.buttonItems.push({ name: '2', value: 2, state: false });
    this.buttonsRoom.buttonItems.push({ name: '3', value: 3, state: false });
    this.buttonsRoom.buttonItems.push({ name: '4', value: 4, state: false });
    this.buttonsRoom.buttonItems.push({ name: '+5', value: 5, state: false });
  }

  makeRoomQuery(item: ItemChipButtonInterface) {
    this.propertiesService.rooms$.next(item.value);
  }
}
