import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterInterface } from 'src/app/interfaces/filter.interface';
import {
  GroupButtonsInterface,
  ItemChipButtonInterface,
} from 'src/app/interfaces/group-buttons.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-parking-filter',
  templateUrl: './parking-filter.component.html',
  styleUrls: ['./parking-filter.component.scss'],
})
export class ParkingFilterComponent implements OnInit {
  buttonsParking: GroupButtonsInterface = { title: '', buttonItems: [] };
  

  constructor(public propertiesService: PropertiesService) {}

  ngOnInit() {
    this.loadGroupButtonsRoom()
  }

  loadGroupButtonsRoom() {
    this.buttonsParking.title = 'Ambientes';
    this.buttonsParking.buttonItems.push({ name: '1', value: 1, state: false });
    this.buttonsParking.buttonItems.push({ name: '2', value: 2, state: false });
    this.buttonsParking.buttonItems.push({ name: '3', value: 3, state: false });
    this.buttonsParking.buttonItems.push({ name: '4', value: 4, state: false });
    this.buttonsParking.buttonItems.push({
      name: '5+',
      value: 5,
      state: false,
    });
  }

  makeParkingQuery(item: ItemChipButtonInterface) {
    this.propertiesService.garage$.next(item.value)
  }
}
