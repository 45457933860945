import { Component, OnInit } from '@angular/core';
import { GoogleReviewsService } from 'src/app/services/google-reviews/google-reviews.service';

@Component({
  selector: 'app-google-reviews',
  templateUrl: './google-reviews.component.html',
  styleUrls: ['./google-reviews.component.scss'],
})
export class GoogleReviewsComponent implements OnInit {
  slideOpts = {
    slidesPerView: 4,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      reverseDirection: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      // when window width is >= 480px
      530: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
      // when window width is >= 640px
      840: {
        slidesPerView: 4,
        spaceBetween: 40,
      },
    },
  };
  constructor(public gRS: GoogleReviewsService) {}

  ngOnInit() {}
}
