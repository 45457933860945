import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  constructor(
      private navCtrl: NavController,
      private router: Router,
    ) { }

  ngOnInit() {}

  goTo(path) {
    const currentUrl = this.router.url;
    console.log(currentUrl)
    
    if(path === 'wizard') {
      if(currentUrl === '/home' || currentUrl === '/home#wizard') {
        document.getElementById("wizard").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
          });
      } else {
        this.navCtrl.navigateForward('home#wizard')
      }
    } else {
      this.navCtrl.navigateForward(path)
    }
  }

}
