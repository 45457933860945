import { Injectable, OnInit } from '@angular/core';
import {
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  setDoc,
  deleteDoc,
  updateDoc,
  query,
  limit,
  where,
  orderBy,
  startAt,
  endAt,
  QueryConstraint,
  addDoc,
} from '@angular/fire/firestore';
import { Timestamp } from 'firebase/firestore';
import { Router } from '@angular/router';

interface LeadsI {
  createdAt: any;
  id?: string;
  fileId: string;
  prospectoId: string;
  accountExecutive: {
    id: string;
    firstName: string;
    lastName: string;
    displayName: string;
    email: string;
    mobile: string;
    photoURL: string;
  };
  applicants: ApplicantsI[];
  guarantors: any[];
  propertyAddress?: string;
  propertyRentCost: number;
  propertyRentExpenses: number;
  propertyUse?: string;
  ownerName?: string;
  ownerEmail?: string;
  ownerMobile?: string;
  ownerPassport?: number;
  ownerAddress?: string;
  interactionLast: any;
  aSearchText: string[];
  property: boolean;
  statusL: {
    name: string;
    chipColor: string;
    iconName: string;
  };
  statusLReason: string;
  statusLComment: string;
  propertyIdeal: {};
  utm: Utm[];
  interactionsText: string;
  gclid: string;
}
export interface Utm {
  url: string;
  utmSource: string;
  utmMedium: string;
  utmCampaign: string;
  utmTerm: string;
  utmContent: string;
  createdAt: Timestamp;
  updatedAt: Timestamp;
}

export interface ApplicantsI {
  firstName: string;
  lastName: string;
  passport?: number;
  email?: string;
  mobile?: string;
  worstSit?: number;
  scoring?: number;
  cda?: string;
  comp?: number; //compromisos mensuales
  nationality?: string;
  workRelation?: string;
  income?: number;
  holder?: boolean;
  docDNI?: string;
  docPaycheck?: string;
  docBills?: string;
  docAutonomousMonotax?: string;
  addressTax?: string;
  preApproveAltert?: string;
  nosisChecked?: boolean;
  CUIT: string;
  birthday: string;
  maritalStatus: string;
  educationLevel: string;
  profession: string;
  dependents: number;
  laborOld: number;
  incomeDeclared: number;
  incomeConsidered: number;
  NSE: string;
  employer: string;
  lawsuitsBankruptciesContests: number;
  commercialReferences: number;
  AFIPRegistrationDate: string;
  monotaxCategory: string;
  retired: boolean;
  employed: boolean;
  monotax: boolean;
  autonomous: boolean;
  bouncedChecks: number;
}

@Injectable({
  providedIn: 'root',
})
export class LeadsService {
  private prospectosRef = collection(this.firestore, 'prospectos');
  constructor(private firestore: Firestore, private router: Router) {}

  async leadAdd(
    applicantMobile: string,
    applicantEmail?: string,
    propertyUse?: string,
    propertyRentCost?: number,
    propertyRentExpenses?: number,
    applicantName?: string,
    utm_source?: string,
    utm_medium?: string,
    utm_campaign?: string,
    utm_term?: string,
    utm_content?: string,
    gclid?: string
  ) {
    console.log('PATH', this.router.url);
    console.log(
      'UTMS',
      utm_source,
      utm_medium,
      utm_medium,
      utm_campaign,
      utm_term,
      utm_content,
      gclid
    );
    const applicantArr = [];
    applicantArr.push({
      firstName: applicantName ? applicantName : '',
      lastName: '',
      passport: 0,
      email: applicantEmail ? applicantEmail : '',
      mobile: applicantMobile ? applicantMobile : '',
      worstSit: 0,
      scoring: 0,
      cda: '',
      comp: '',
      nationality: '',
      workRelation: '',
      income: 0,
      holder: false,
      docDNI: '',
      docPaycheck: '',
      docBills: '',
      docAutonomousMonotax: '',
      addressTax: '',
      preApproveAltert: '',
      nosisChecked: false,
      CUIT: '',
      birthday: '',
      maritalStatus: '',
      educationLevel: '',
      profession: '',
      dependents: 0,
      laborOld: 0,
      incomeDeclared: 0,
      incomeConsidered: 0,
      NSE: '',
      employer: '',
      lawsuitsBankruptciesContests: 0,
      commercialReferences: 0,
      AFIPRegistrationDate: '',
      monotaxCategory: '',
      retired: false,
      employed: false,
      monotax: false,
      autonomous: false,
      bouncedChecks: 0,
    });
    const leadData: LeadsI = {
      createdAt: Timestamp.now(),
      fileId: '',
      prospectoId: '',
      accountExecutive: {
        id: '',
        firstName: '',
        lastName: '',
        displayName: '',
        email: '',
        mobile: '',
        photoURL: '',
      },
      applicants: applicantArr,
      guarantors: [],
      propertyAddress: '',
      propertyRentCost: propertyRentCost ? propertyRentCost : 0,
      propertyRentExpenses: propertyRentExpenses ? propertyRentExpenses : 0,
      propertyUse: propertyUse ? propertyUse : '',
      ownerName: '',
      ownerEmail: '',
      ownerMobile: '',
      ownerPassport: 0,
      ownerAddress: '',
      interactionLast: Timestamp.now(),
      aSearchText: [],
      property: false,
      statusL: {
        name: 'Sin asignar',
        chipColor: 'danger',
        iconName: 'person',
      },
      statusLReason: '',
      statusLComment: '',
      propertyIdeal: {},
      gclid: gclid ? gclid : '',
      utm: [
        {
          url: '',
          utmSource: utm_source ? utm_source : '',
          utmMedium: utm_medium ? utm_medium : '',
          utmCampaign: utm_campaign ? utm_campaign : '',
          utmTerm: utm_term ? utm_term : '',
          utmContent: utm_content ? utm_content : '',
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        },
      ],
      interactionsText: '',
    };
    console.log(leadData.utm);
    const r = await addDoc(this.prospectosRef, leadData);
  }
}
