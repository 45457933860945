import { Component, Input, OnInit } from '@angular/core';
import { SimpleIntroInterface } from 'src/app/interfaces/simple-intro.interface';

@Component({
  selector: 'app-simple-intro',
  templateUrl: './simple-intro.component.html',
  styleUrls: ['./simple-intro.component.scss'],
})
export class SimpleIntroComponent implements OnInit {

  @Input() intro: SimpleIntroInterface;

  constructor() { }

  ngOnInit() {
  }

}
