import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FilterInterface } from 'src/app/interfaces/filter.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-surface-filter',
  templateUrl: './surface-filter.component.html',
  styleUrls: ['./surface-filter.component.scss'],
})
export class SurfaceFilterComponent implements OnInit {
  surfaceType: string;

  metersA: number;
  metersB: number;

  constructor(private propertiesService: PropertiesService) {}

  ngOnInit() {
  }

  makeSurfaceQuery() {
    switch (this.surfaceType) {
      case 'total':
        this.propertiesService.sMetersTA$.next(this.metersA);
        this.propertiesService.sMetersTB$.next(this.metersB);
        this.propertiesService.sMetersCA$.next(0);
        this.propertiesService.sMetersCB$.next(0);
        this.propertiesService.sMetersTA$.subscribe(res => this.metersA = res)
        this.propertiesService.sMetersTB$.subscribe(res => this.metersB = res)
        break;

      case 'cubierta':
        this.propertiesService.sMetersCA$.next(this.metersA);
        this.propertiesService.sMetersCB$.next(this.metersB);
        this.propertiesService.sMetersTA$.next(0);
        this.propertiesService.sMetersTB$.next(0);
        this.propertiesService.sMetersCA$.subscribe(res => this.metersA = res)
        this.propertiesService.sMetersCB$.subscribe(res => this.metersB = res)
        break;
    }
  }
}
