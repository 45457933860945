import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { GroupButtonsInterface, ItemChipButtonInterface } from 'src/app/interfaces/group-buttons.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-group-round-button',
  templateUrl: './group-round-button.component.html',
  styleUrls: ['./group-round-button.component.scss'],
})
export class GroupRoundButtonComponent implements OnInit {
  @Input() groupButtons: GroupButtonsInterface;
  @Output() makeQuery = new EventEmitter<ItemChipButtonInterface>();

  constructor(public propertiesService: PropertiesService) { }

  ngOnInit() {
  }

  makeFilterQuery(buttonRound:ItemChipButtonInterface) {
    this.putStateFalseAll();
    buttonRound.state = !buttonRound.state;
    this.makeQuery.emit(buttonRound)
  }

  putStateFalseAll() {
    this.groupButtons.buttonItems.map(
      res => res.state = false
    )
  }

}
