import { Component, OnInit } from '@angular/core';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss'],
})
export class FilterTagComponent implements OnInit {

  constructor(public propertiesService: PropertiesService) {}

  ngOnInit() {
    this.propertiesService.cities$.subscribe()
    
  }

  deleteFilterRooms() {
    this.propertiesService.rooms$.next(null);
  }

  updateFilterCities(city: string) {
    const cities = this.propertiesService.cities$.value;

    const index = cities.indexOf(city);
    cities.splice(index, 1);

    this.propertiesService.cities$.next(cities);
  }

  deleteFilterPropertyType() {
    this.propertiesService.propertyType$.next(null)
  }

  deleteFilterBathrooms() {
    this.propertiesService.bathrooms$.next(null)
  }

  deleteFilterGarage() {
    this.propertiesService.garage$.next(null)
  }

  deleteFilterPrice() {
    this.propertiesService.priceA$.next(null)
    this.propertiesService.priceB$.next(null)
  }

  deleteFilterExpenses() {
    this.propertiesService.expensesA$.next(null)
    this.propertiesService.expensesB$.next(null)
  }

  deleteFilterRentExpenses() {
    this.propertiesService.priceExpensesA$.next(null)
    this.propertiesService.priceExpensesB$.next(null)
  }

  deleteFilterProvince() {
    this.propertiesService.province$.next(null)
    this.propertiesService.cities$.next(null)
  }

  deleteTotalSurfaceFilter() {
    this.propertiesService.sMetersTA$.next(null)
    this.propertiesService.sMetersTB$.next(null)
  }

  deleteCoverSurfaceFilter() {
    this.propertiesService.sMetersCA$.next(null)
    this.propertiesService.sMetersCB$.next(null)
  }
}
