import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
  FormArray,
} from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { LeadsService } from 'src/app/services/leads/leads.service';
import { UtmService } from 'src/app/services/utm/utm.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private leadsService: LeadsService,
    private alertController: AlertController,
    private utmService: UtmService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      mobile: [
        '',
        [
          Validators.minLength(10),
          Validators.required,
          Validators.maxLength(15),
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      email: [
        '',
        [Validators.email, Validators.required, Validators.maxLength(150)],
      ],
    });
  }

  get mobile() {
    return this.form.get('mobile');
  }

  get email() {
    return this.form.get('email');
  }

  send() {
    if (this.form.valid) {
      const data = this.form.value;
      this.leadsService.leadAdd(
        data.mobile,
        data.email,
        '',
        0,
        0,
        '',
        this.utmService.utm_source ? this.utmService.utm_source : '',
        this.utmService.utm_medium ? this.utmService.utm_medium : 'contacto',
        this.utmService.utm_campaign ? this.utmService.utm_campaign : '',
        this.utmService.utm_term ? this.utmService.utm_term : '',
        this.utmService.utm_content ? this.utmService.utm_content : ''
      );

      this.form.reset();
      this.presentAlert('Hemos recibo su email, pronto estaremos en contacto');
    }
  }

  async presentAlert(message: string) {
    const alert = await this.alertController.create({
      header: message,
      buttons: [
        {
          text: 'Ok',
          cssClass: 'alertButton',
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
  }
}
