import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterInterface } from 'src/app/interfaces/filter.interface';
import {
  GroupButtonsInterface,
  ItemChipButtonInterface,
} from 'src/app/interfaces/group-buttons.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-bathroom-filter',
  templateUrl: './bathroom-filter.component.html',
  styleUrls: ['./bathroom-filter.component.scss'],
})
export class BathroomFilterComponent implements OnInit {
  buttonsBathroom: GroupButtonsInterface = { title: '', buttonItems: [] };
  filter: FilterInterface;

  filterSubcription: Subscription;

  constructor(public propertiesService: PropertiesService) {}

  ngOnInit() {
    this.loadGroupButtonsRoom();
  }

  loadGroupButtonsRoom() {
    this.buttonsBathroom.title = 'Ambientes';
    this.buttonsBathroom.buttonItems.push({
      name: '1',
      value: 1,
      state: false,
    });
    this.buttonsBathroom.buttonItems.push({
      name: '2',
      value: 2,
      state: false,
    });
    this.buttonsBathroom.buttonItems.push({
      name: '3',
      value: 3,
      state: false,
    });
    this.buttonsBathroom.buttonItems.push({
      name: '4',
      value: 4,
      state: false,
    });
    this.buttonsBathroom.buttonItems.push({
      name: '5+',
      value: 5,
      state: false,
    });
  }

  makeBathroomQuery(item: ItemChipButtonInterface) {
    this.propertiesService.bathrooms$.next(item.value);
  }
}
