import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  public selectionLabelShow = true;
  private fragment: string;
  constructor(
    private platform: Platform,
    private navCtrl: NavController,
    private router: Router
  ) {}

  ngOnInit() {}

  interface() {
    if (this.platform.is('desktop')) {
      return 'popover';
    } else {
      return 'action-sheet';
    }
  }

  selectionFocus() {
    this.selectionLabelShow = false;
  }
  selectionChange(e) {
    this.selectionLabelShow = false;
    this.goTo(e.detail.value);
    console.log(e.detail.value);
    console.log(this.router.url);
    console.log(this.router.url.split('#')[1]);
  }
  goTo(path) {
    const currentUrl = this.router.url;
    
    if(path === 'wizard') {
      if(currentUrl === '/home' || currentUrl === '/home#wizard') {
        document.getElementById("wizard").scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
          });
      } else {
        this.navCtrl.navigateForward('home#wizard')
      }
    } else {
      this.navCtrl.navigateForward(path)
    }
  }
}
