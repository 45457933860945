import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vip',
  templateUrl: './vip.component.html',
  styleUrls: ['./vip.component.scss'],
})
export class VipComponent implements OnInit {
  slideOpts = {
    slidesPerView: 3,
    autoplay: {
      delay: 4000,
    },
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 2,
        spaceBetween: 5,
      },
      // when window width is >= 480px
      530: {
        slidesPerView: 4,
        spaceBetween: 7,
      },
      // when window width is >= 640px
      840: {
        slidesPerView: 6,
        spaceBetween: 10,
      },
    },
  };
  constructor() {}

  ngOnInit() {}
}
