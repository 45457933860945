import { Component, Input, OnInit } from '@angular/core';
import { IdealGuranteeInterface } from 'src/app/interfaces/ideal-guarantee.interface';

@Component({
  selector: 'app-ideal-guarantee',
  templateUrl: './ideal-guarantee.component.html',
  styleUrls: ['./ideal-guarantee.component.scss'],
})
export class IdealGuaranteeComponent implements OnInit {

  @Input() idealGuarantee: IdealGuranteeInterface;

  constructor() { }

  ngOnInit() {
  }

}
