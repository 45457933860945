import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ClienGoService } from './services/clien-go/clien-go.service';
import { SirenaService } from './services/sirena/sirena.service';
import { initializeApp } from 'firebase/app';
import { environment } from '../environments/environment.prod';
import { getAnalytics } from 'firebase/analytics';
import { ActivatedRoute } from '@angular/router';
import { UtmService } from './services/utm/utm.service';

const app = initializeApp(environment.config);
const analytics = getAnalytics(app);

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private cliengoService: ClienGoService,
    private sirenaService: SirenaService,
    private platform: Platform,
    private activatedRoute: ActivatedRoute,
    private utmService: UtmService
  ) {
    //if (this.platform.is('desktop')) this.loadClienGo();
    //if (this.platform.is('mobile')) this.loadSirena();
    this.loadSirena();
    this.activatedRoute.queryParams.subscribe((r) => {
      console.log('activatedRoute.queryParams11', r);
      console.log('activatedRoute.queryParamsDefault', this.utmService);
      if (r.utm_source) {
        this.utmService.utm_source = r.utm_source;
      }
      if (r.utm_medium) {
        this.utmService.utm_medium = r.utm_medium;
      }
      if (r.utm_campaign) {
        this.utmService.utm_campaign = r.utm_campaign;
      }
      if (r.utm_term) {
        this.utmService.utm_term = r.utm_term;
      }
      if (r.utm_content) {
        this.utmService.utm_term = r.utm_content;
      }

      if (r.gclid) {
        this.utmService.gclid = r.gclid;
      } else {
        this.utmService.gclid = '';
      }
    });
  }

  // private loadClienGo() {
  //   // You can load multiple scripts by just providing the key as argument into load method of the service
  //   this.cliengoService
  //     .load('chatBot')
  //     .then((data) => {
  //       // Script Loaded Successfully
  //     })
  //     .catch((error) => console.log(error));
  // }

  private loadSirena() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.sirenaService
      .load('chatBot')
      .then((data) => {
        // Script Loaded Successfully
      })
      .catch((error) => console.log(error));
  }
}
