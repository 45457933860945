import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ViewerImagesService } from 'src/app/services/viewer-images/viewer-images.service';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements OnInit, OnDestroy {
  @ViewChild(IonSlides) slides: IonSlides;
  slideOpts = {
    initialSlide: 1,
    speed: 400,
    loop: true,
  };
  imagesServiceSubscription: Subscription;
  images: string[];

  constructor(private imagesServie: ViewerImagesService) { }

  ngOnInit() {
    this.imagesServiceSubscription = this.imagesServie.imagesArray$.subscribe(
      images => {
        this.images = images
      }
    )
  }

  ngOnDestroy(): void {
    
  }

  nextSlide() {
    this.slides.slideNext()
  }

  backSlide() {
    this.slides.slidePrev()
  }

}
