import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtmService {
  utm_source: string = 'Organic';
  utm_medium: string = '';
  utm_campaign: string = '';
  utm_term: string = '';
  utm_content: string = '';
  gclid: string = '';
  constructor() {}
}
