import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  ObjectUnsubscribedError,
  Observable,
  of,
} from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
  FilterInterface,
  FilterProvinceInterface,
} from 'src/app/interfaces/filter.interface';
import { PropertiesInterface } from 'src/app/interfaces/properties.interface';

import {
  Firestore,
  collection,
  collectionData,
  doc,
  docData,
  addDoc,
  deleteDoc,
  updateDoc,
  query,
  limit,
  where,
  orderBy,
  startAt,
  endAt,
  QueryConstraint,
} from '@angular/fire/firestore';
import { ProvincesInterface } from 'src/app/interfaces/setup.interface';
import {
  PropiedadesUCitiesInterface,
  PropiedadesUProvincesInterface,
} from 'src/app/interfaces/stats.interface';
import { DocumentData } from 'rxfire/firestore/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  address$ = new BehaviorSubject(null);
  bathrooms$ = new BehaviorSubject(null);
  cities$ = new BehaviorSubject(null);
  createdAtA$ = new BehaviorSubject(null);
  createdAtB$ = new BehaviorSubject(null);
  createdAtText$ = new BehaviorSubject(null);
  expensesA$ = new BehaviorSubject(null);
  expensesB$ = new BehaviorSubject(null);
  garage$ = new BehaviorSubject(null);
  limit$ = new BehaviorSubject(null);
  location$ = new BehaviorSubject(null);
  orderByPosition$ = new BehaviorSubject(null);
  outstanding$ = new BehaviorSubject(null);
  priceA$ = new BehaviorSubject(null);
  priceB$ = new BehaviorSubject(null);
  priceExpensesA$ = new BehaviorSubject(null);
  priceExpensesB$ = new BehaviorSubject(null);
  propertiesList$: Observable<any[]>;
  propertyDetail$: Observable<PropertiesInterface>;
  propertyType$ = new BehaviorSubject(null);
  province$ = new BehaviorSubject(null);
  provinceOther$ = new BehaviorSubject(null);
  publish$ = new BehaviorSubject(true);
  rentType$ = new BehaviorSubject(null);
  rooms$ = new BehaviorSubject(null);
  sMeters$ = new BehaviorSubject('total');
  sMetersCA$ = new BehaviorSubject(null);
  sMetersCB$ = new BehaviorSubject(null);
  sMetersTA$ = new BehaviorSubject(null);
  sMetersTB$ = new BehaviorSubject(null);
  search$ = new BehaviorSubject(null);
  share$ = new BehaviorSubject(null);
  private propiedadesRef = collection(this.firestore, 'propiedades');
  private propiedadesURef = doc(this.firestore, `/--stats--/propiedadesU`);

  private filterSource = docData(
    this.propiedadesURef
  ) as Observable<PropiedadesUProvincesInterface>;

  currentFilter = this.filterSource.pipe(
    map((r) => {
      const res = this.provinceGet(r.provinces);
      return res;
    })
  );

  private propertiesSource = new BehaviorSubject<PropertiesInterface[]>(null);
  currentProperties = this.propertiesSource;

  private fiterTagSource = new BehaviorSubject<string[]>(['casa', '2 amb']);

  currentFiterTag = this.fiterTagSource.asObservable();

  // filter observable
  filterObject: FilterInterface = {
    bathrooms: 0,
    cities: [],
    province: '',
    expensesA: 0,
    expensesB: 0,
    garage: 0,
    priceA: 0,
    priceB: 0,
    priceExpensesA: 0,
    priceExpensesB: 0,
    propertyType: '',
    rooms: 0,
    sMetersCA: 0,
    sMetersCB: 0,
    sMetersTA: 0,
    sMetersTB: 0,
  };

  filterObjectSource = new BehaviorSubject<FilterInterface>(this.filterObject);
  currentFilterObject = this.filterObjectSource.asObservable();

  constructor(private firestore: Firestore) {}

  private provinceGet(
    object: PropiedadesUCitiesInterface
  ): FilterProvinceInterface[] {
    let o = [];
    for (let key in object) {
      let oo = [];
      for (let k in object[key].cities) {
        if (object[key].cities[k]) {
          oo.push({
            name: k,
            total: object[key].cities[k],
            filterChecked: false,
          });
        }
      }
      oo.sort(this.compare);
      if (object[key].qty)
        o.push({
          name: key,
          total: object[key].qty,
          locations: oo,
          filterChecked: false,
        });
    }
    return o.sort(this.compare);
  }

  private compare(a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  }

  deleteChip(chip: string) {
    const index = this.fiterTagSource.value.indexOf(chip);
    this.fiterTagSource.value.splice(index, 1);
  }

  // getProperties() {
  //   let varProperties: any = collectionData(
  //     query(this.propiedadesRef, limit(3)),
  //     {
  //       idField: 'id',
  //     }
  //   );

  //   varProperties.subscribe((res) => this.propertiesSource.next(res));
  // }

  getPropertiesFiltered() {
    let varRoom;
    let locationArray;
    let priceA;

    this.currentFilterObject.subscribe((res) => {
      console.log(res);
      varRoom = res.rooms;
      locationArray = res.cities;
      priceA = Number(res.priceA) ? res.priceA : 0;
    });

    console.log(priceA);

    let properties: any = collectionData(
      query(
        this.propiedadesRef,
        // where('rooms', '==', varRoom),
        // where('city', 'in', locationArray),
        where('price', '>', priceA),
        limit(10)
      ),
      {
        idField: 'id',
      }
    );

    properties.subscribe((res) => {
      this.propertiesSource.next(res);
    });
  }

  // getPropertiesLocation() {
  //   let locationArray;
  //   this.currentFilterObject.subscribe((res) => (locationArray = res.cities));

  //   let properties: any = collectionData(
  //     query(this.propiedadesRef, where('city', 'in', locationArray), limit(10)),
  //     {
  //       idField: 'id',
  //     }
  //   );

  //   properties.subscribe((res) => {
  //     this.propertiesSource.next(res);
  //   });
  // }

  // updateFilterObject(filterObject: FilterInterface) {
  //   this.filterObjectSource.next(filterObject);
  //   this.currentFilterObject.subscribe((res) => console.log(res));
  // }

  addLocation() {}

  //Agregó Fede - ini

  listGet() {
    try {
      this.propertiesList$ = combineLatest([
        this.publish$,
        this.province$,
        this.cities$,
        this.address$,
        this.priceA$,
        this.priceB$,
        this.expensesA$,
        this.expensesB$,
        this.priceExpensesA$,
        this.priceExpensesB$,
        this.sMetersTA$,
        this.sMetersTB$,
        this.sMetersCA$,
        this.sMetersCB$,
        this.rooms$,
        this.bathrooms$,
        this.garage$,
        this.rentType$,
        this.propertyType$,
        this.outstanding$,
        this.createdAtA$,
        this.createdAtB$,
        this.search$,
        this.limit$,
        this.orderByPosition$,
      ]).pipe(
        switchMap(
          ([
            publish,
            province,
            cities,
            address,
            priceA,
            priceB,
            expensesA,
            expensesB,
            priceExpensesA,
            priceExpensesB,
            sMetersTA,
            sMetersTB,
            sMetersCA,
            sMetersCB,
            rooms,
            bathrooms,
            garage,
            rentType,
            propertyType,
            outstanding,
            createdAtA,
            createdAtB,
            search,
            limits,
            orderByPosition,
          ]) => {
            const constraints: QueryConstraint[] = [];
            if (publish) constraints.push(where('publish', '==', publish));
            if (province) constraints.push(where('province', '==', province));
            if (cities && cities.length > 0) {
              constraints.push(where('city', 'in', cities));
            }

            if (address) {
              constraints.push(startAt(address));
              constraints.push(endAt(address + '\uf8ff'));
            }
            switch (rooms) {
              case null:
                break;
              case 5:
                constraints.push(where('rooms', '>=', 5));
                constraints.push(orderBy('rooms', 'asc'));
                break;
              default:
                constraints.push(where('rooms', '==', Number(rooms)));
                break;
            }
            switch (bathrooms) {
              case null:
                break;
              case 5:
                constraints.push(where('bathrooms', '>=', 5));
                constraints.push(orderBy('bathrooms', 'asc'));
                break;
              default:
                constraints.push(where('bathrooms', '==', Number(bathrooms)));
                break;
            }
            switch (garage) {
              case null:
                break;
              case 5:
                constraints.push(where('garage', '>=', 5));
                constraints.push(orderBy('garage', 'asc'));
                break;
              default:
                constraints.push(where('garage', '==', Number(garage)));
                break;
            }
            if (rentType) constraints.push(where('rentType', '==', rentType));
            if (propertyType)
              constraints.push(where('propertyType', '==', propertyType));
            if (outstanding)
              constraints.push(where('outstanding', '==', outstanding));

            constraints.push(where('USD', '==', false));
            if (priceA && priceB) {
              constraints.push(where('price', '>=', priceA));
              constraints.push(where('price', '<=', priceB));
              constraints.push(orderBy('price'));
            }
            if (priceA && !priceB) {
              constraints.push(where('price', '>=', priceA));
              constraints.push(orderBy('price'));
            }
            if (!priceA && priceB) {
              constraints.push(where('price', '<=', priceB));
              constraints.push(orderBy('price'));
            }
            if (
              !(priceA || priceB) &&
              (expensesA || expensesB) &&
              !(priceExpensesA || priceExpensesB) &&
              !(sMetersTA || sMetersTB) &&
              !(sMetersCA || sMetersCB) &&
              !(createdAtA || createdAtB)
            ) {
              if (expensesA && expensesB) {
                constraints.push(where('expenses', '>=', expensesA));
                constraints.push(where('expenses', '<=', expensesB));
                constraints.push(orderBy('expenses'));
              }
              if (expensesA && !expensesB) {
                constraints.push(where('expenses', '>=', expensesA));
                constraints.push(orderBy('expenses'));
              }
              if (!expensesA && expensesB) {
                constraints.push(where('expenses', '<=', expensesB));
                constraints.push(orderBy('expenses'));
              }
            }
            if (
              !(priceA || priceB) &&
              !(expensesA || expensesB) &&
              (priceExpensesA || priceExpensesB) &&
              !(sMetersTA || sMetersTB) &&
              !(sMetersCA || sMetersCB) &&
              !(createdAtA || createdAtB)
            ) {
              if (priceExpensesA && priceExpensesB) {
                constraints.push(where('priceExpenses', '>=', priceExpensesA));
                constraints.push(where('priceExpenses', '<=', priceExpensesB));
                constraints.push(orderBy('priceExpenses'));
              }
              if (priceExpensesA && !priceExpensesB) {
                constraints.push(where('priceExpenses', '>=', priceExpensesA));
                constraints.push(orderBy('priceExpenses'));
              }
              if (!priceExpensesA && priceExpensesB) {
                constraints.push(where('priceExpenses', '<=', priceExpensesB));
                constraints.push(orderBy('priceExpenses'));
              }
            }
            if (
              !(priceA || priceB) &&
              !(expensesA || expensesB) &&
              !(priceExpensesA || priceExpensesB) &&
              (sMetersTA || sMetersTB) &&
              !(sMetersCA || sMetersCB) &&
              !(createdAtA || createdAtB)
            ) {
              if (sMetersTA && sMetersTB) {
                constraints.push(where('sMetersT', '>=', sMetersTA));
                constraints.push(where('sMetersT', '<=', sMetersTB));
                constraints.push(orderBy('sMetersT'));
              }
              if (sMetersTA && !sMetersTB) {
                constraints.push(where('sMetersT', '>=', sMetersTA));
                constraints.push(orderBy('sMetersT'));
              }
              if (!sMetersTA && sMetersTB) {
                constraints.push(where('sMetersT', '<=', sMetersTB));
                constraints.push(orderBy('sMetersT'));
              }
            }
            if (
              !(priceA || priceB) &&
              !(expensesA || expensesB) &&
              !(priceExpensesA || priceExpensesB) &&
              !(sMetersTA || sMetersTB) &&
              (sMetersCA || sMetersCB) &&
              !(createdAtA || createdAtB)
            ) {
              if (sMetersCA && sMetersCB) {
                constraints.push(where('sMetersC', '>=', sMetersCA));
                constraints.push(where('sMetersC', '<=', sMetersCB));
                constraints.push(orderBy('sMetersC'));
              }
              if (sMetersCA && !sMetersCB) {
                constraints.push(where('sMetersC', '>=', sMetersCA));
                constraints.push(orderBy('sMetersC'));
              }
              if (!sMetersCA && sMetersCB) {
                constraints.push(where('sMetersC', '<=', sMetersCB));
                constraints.push(orderBy('sMetersC'));
              }
            }
            if (createdAtA && createdAtB) {
              if (
                !(priceA || priceB) &&
                !(expensesA || expensesB) &&
                !(priceExpensesA || priceExpensesB) &&
                !(sMetersTA || sMetersTB) &&
                !(sMetersCA || sMetersCB)
              ) {
                constraints.push(where('createdAt', '>=', createdAtA));
                constraints.push(where('createdAt', '<=', createdAtB));
              }
            }
            if (
              !(priceA || priceB) &&
              !(expensesA || expensesB) &&
              !(priceExpensesA || priceExpensesB) &&
              !(sMetersTA || sMetersTB) &&
              !(sMetersCA || sMetersCB) &&
              !(createdAtA || createdAtB) &&
              orderByPosition
            ) {
              if (orderByPosition) {
                constraints.push(orderBy('position', orderByPosition));
              }
            }
            if (search) {
              constraints.push(startAt(search));
              constraints.push(endAt(search + '\uf8ff'));
            }

            if (limits) constraints.push(limit(Number(limits)));

            constraints.push(orderBy('position', 'desc'));
            constraints.push(orderBy('createdAt', 'desc'));
            return collectionData(query(this.propiedadesRef, ...constraints), {
              idField: 'id',
            });
          }
        )
      );
    } catch (e) {
      console.error(e);
    }
  }

  propertyGet(id: string) {
    const propiedadesURef = doc(this.firestore, `/propiedades/${id}`);
    this.propertyDetail$ = docData(
      propiedadesURef
    ) as Observable<PropertiesInterface>;
  }

  //Agregó Fede - fin
}
