import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import {
  FilterInterface,
  FilterProvinceInterface,
  FilterCityInterface,
} from 'src/app/interfaces/filter.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss'],
})
export class LocationFilterComponent implements OnInit, OnDestroy {
  filters: FilterProvinceInterface[];
  currentFilterSubscription: Subscription;

  filter: FilterInterface;
  filterSubscription: Subscription;

  constructor(public propertiesService: PropertiesService) {}

  ngOnInit() {
    this.currentFilterSubscription =
      this.propertiesService.currentFilter.subscribe(
        (res) => (this.filters = res)
      );

    this.filterSubscription =
      this.propertiesService.currentFilterObject.subscribe(
        (res) => (this.filter = res)
      );
  }

  ngOnDestroy(): void {
    this.currentFilterSubscription.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  selectCities(location: FilterCityInterface) {
    const cities: string[] = this.propertiesService.cities$.value ? this.propertiesService.cities$.value : [];
    cities.push(location.name);
    this.propertiesService.cities$.next(cities);
  }

  selectProvinces(province: FilterProvinceInterface) {
    this.propertiesService.cities$.next(null)
    this.propertiesService.province$.next(province.name);
  }

  cityChecked(city) {
    const cities: string[] = this.propertiesService.cities$.value ? this.propertiesService.cities$.value : [];
    const index = cities.indexOf(city);
    if(index === -1) return false;
    else return true;
  }
}
