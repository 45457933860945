import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewerImagesService {

  private imagesArray= new BehaviorSubject<string[]>(null);
  imagesArray$ = this.imagesArray.asObservable()

  constructor() { }

  loadImages(images: string[]) {
    this.imagesArray.next(images)
  }
}
