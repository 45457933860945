import { Component, Input, OnInit } from '@angular/core';
import { ChooseUsInterface } from 'src/app/interfaces/choose-us.interface';

@Component({
  selector: 'app-choose-us',
  templateUrl: './choose-us.component.html',
  styleUrls: ['./choose-us.component.scss'],
})
export class ChooseUsComponent implements OnInit {
  @Input() chooseUs: ChooseUsInterface;

  constructor() { }

  ngOnInit() {}

}
