import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import {
  FilterCityInterface,
  FilterInterface,
  FilterProvinceInterface,
} from 'src/app/interfaces/filter.interface';
import { GroupButtonsInterface } from 'src/app/interfaces/group-buttons.interface';
import { PricesInterface } from 'src/app/interfaces/prices.interfaces';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  filters: FilterProvinceInterface[];
  // buttonsRoom: GroupButtonsInterface = {title: '', valuesNames: []};
  // buttonsBathroom: GroupButtonsInterface = {title: '', valuesNames: []};
  // // buttonsParking: GroupButtonsInterface = {title: '', valuesNames: []};
  // pricesInputs: PricesInterface = {title: '', intervalPricesItem: []};

  currentFilterSubscription: Subscription;

  filterObject: FilterInterface;

  constructor(
    private propertiesService: PropertiesService,
    private modalCtrl: ModalController
  ) {}

  ngOnInit() {
    // this.currentFilterSubscription = this.propertiesService.currentFilter.subscribe(
    //   (res) => (this.filters = res)
    // );
    // this.loadGroupButtonsRoom();
    // this.loadGroupButtonsBathroom();
    // this.loadGroupButtonsParking();
  }

  toggleCheckbox(item: FilterCityInterface) {
    item.filterChecked = !item.filterChecked;
    this.propertiesService.getPropertiesFiltered();
  }

  // loadPrices() {
  //   this.pricesInputs.title = 'Precio';
  //   this.pricesInputs.intervalPricesItem.push({
  //     title: 'Alquiler',
  //     from: '',
  //     untill: ''
  //   })

  //   this.pricesInputs.intervalPricesItem.push({
  //     title: 'Expensas',
  //     from: '',
  //     untill: ''
  //   })

  //   this.pricesInputs.intervalPricesItem.push({
  //     title: 'Alquiler + expensas',
  //     from: '',
  //     untill: ''
  //   })
  // }

  // loadGroupButtonsRoom() {
  //   this.buttonsRoom.title = 'Ambientes';
  //   this.buttonsRoom.valuesNames = ['1', '2', '3', '4', '5+'];
  // }

  // loadGroupButtonsBathroom() {
  //   this.buttonsBathroom.title = 'Baños';
  //   this.buttonsBathroom.valuesNames = ['1', '2', '3', '4'];
  // }

  // loadGroupButtonsParking() {
  //   this.buttonsParking.title = 'Estacionamientos';
  //   this.buttonsParking.valuesNames = ['1', '2', '3', '4'];
  // }

  // ngOnDestroy(): void {
  //     this.currentFilterSubscription.unsubscribe();
  // }

  dismissAll() {
    this.modalCtrl.dismiss();
  }
}
