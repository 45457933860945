import { Component, OnInit } from '@angular/core';
import {
  PaymentMethodsElementInterface,
  PaymentMethodsInterface,
} from 'src/app/interfaces/payment-methods.interface';

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrls: ['./payment-methods.component.scss'],
})
export class PaymentMethodsComponent implements OnInit {
  payments: PaymentMethodsInterface = {
    title: '',
    methodsTypes: [
      { src: '', type: '' },
      { src: '', type: '' },
      { src: '', type: '' },
      { src: '', type: '' },
      { src: '', type: '' },
    ],
  };

  constructor() {}

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    this.payments.title = 'Medios de pago';

    this.payments.methodsTypes[0].type = 'Efectivo';
    this.payments.methodsTypes[0].src = 'assets/styles/img/payment/coins.png';

    this.payments.methodsTypes[1].type = 'Transferencia';
    this.payments.methodsTypes[1].src = 'assets/styles/img/payment/cash.png';

    this.payments.methodsTypes[2].type = 'Tarjetas de crédito o débito';
    this.payments.methodsTypes[2].src =
      'assets/styles/img/payment/credit_card.png';

    this.payments.methodsTypes[3].type = 'MercadoPago';
    this.payments.methodsTypes[3].src =
      'assets/styles/img/payment/mercado_pago.png';

    this.payments.methodsTypes[4].type = 'Bitcoin';
    this.payments.methodsTypes[4].src = 'assets/styles/img/payment/bitcoin.png';
  }
}
