import { Component, OnInit, ViewChild } from '@angular/core';
import { IonSlides, IonSlide } from '@ionic/angular';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
  @ViewChild(IonSlides) slides: IonSlides;
  constructor() {}

  ngOnInit() {}

  slidePrev() {
    this.slides.slidePrev();
  }
  slideNext() {
    this.slides.slideNext();
  }

  slideOpts = {
    slidesPerView: 1,
    spaceBetween: 40,
    breakpoints: {
      600: {
        slidesPerView: 2
      },
      900: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
    },
  };
}
