import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-request-more-info',
  templateUrl: './request-more-info.component.html',
  styleUrls: ['./request-more-info.component.scss'],
})
export class RequestMoreInfoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
