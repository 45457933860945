import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterInterface } from 'src/app/interfaces/filter.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

export interface PropertyTypeInterface {
  name: string;
  filterChecked: boolean;
}

@Component({
  selector: 'app-property-type-filter',
  templateUrl: './property-type-filter.component.html',
  styleUrls: ['./property-type-filter.component.scss'],
})
export class PropertyTypeFilterComponent implements OnInit, OnDestroy {
  propertyType: PropertyTypeInterface[] = [
    { name: 'Casa', filterChecked: false },
    { name: 'Departamento', filterChecked: false },
    { name: 'Oficina', filterChecked: false },
    { name: 'Local Comercial', filterChecked: false },
    { name: 'PH', filterChecked: false },
  ];

  filter: FilterInterface;
  subscriptionsFilter: Subscription;

  constructor(public propertiesService: PropertiesService) {}

  ngOnInit() {
    this.subscriptionsFilter =
      this.propertiesService.currentFilterObject.subscribe(
        (res) => (this.filter = res)
      );
  }

  filterByType(item: PropertyTypeInterface) {
    this.clearListTypePropertye();
    item.filterChecked = !item.filterChecked;
    this.propertiesService.propertyType$.next(item.name);
    // this.filter.propertyType = item.name;
    // this.propertiesService.updateFilterObject(this.filter)
  }

  clearListTypePropertye() {
    this.propertyType.map((type) => (type.filterChecked = false));
  }

  ngOnDestroy(): void {}
}
