import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleReviewsService {
  url =
    'https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyA7H6Oj3lQjCZ5jNNdlzFM5GSfWnneiibc&placeid=ChIJA20N4C21vJURo7zd_UokBrU&language=es-419&fields=reviews,user_ratings_total,rating&place_Id=ChIJA20N4C21vJURo7zd_UokBrU';
  reviews$: Observable<HttpResponse<any>> = this.http.get(this.url, {
    observe: 'response',
    responseType: 'json',
  });
  constructor(private http: HttpClient) {}
}
