import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent implements OnInit {
  slideOpts = {
    slidesPerView: 1,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
      reverseDirection: false,
      pauseOnMouseEnter: true,
    },
    loop: true,
  };

  brokers = [
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2F1587053961621_descarga%20(6).jfif?alt=media&token=700d7399-d73f-4257-9bda-2842477285f1',
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2Ftizado_kTCggQu.jpg?alt=media&token=489552cd-56d4-4e31-8c53-d8cf22f8dd3c',
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2FMigliorisiGrande_1.jpg?alt=media&token=213904b6-c0f3-44d5-b35e-9f03f402a547',
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2Flepore.PNG?alt=media&token=b33e51a8-2af4-457d-8b59-06bbb583f6e3',
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2F1569517784552_llauro.png?alt=media&token=6eb5e25a-9965-4cfd-bb4b-d287e42dcb8b',
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2Fmieres_prop_oXwVo5o.jpg?alt=media&token=5dd6da3e-9c83-403f-bf9a-2ef5c98a909e',
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2Ftoribio_achaval_dscOsim.JPG?alt=media&token=4f150c1e-03ec-4122-80df-81f0ab168759',
    'https://firebasestorage.googleapis.com/v0/b/garantiaya.appspot.com/o/inmobiliarias%2FLOGO_2.JPG?alt=media&token=838f6788-ccab-434e-bf73-085068468d7c',
  ];
  constructor() {}

  ngOnInit() {}
}
