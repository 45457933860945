import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooseUsComponent } from './components/choose-us/choose-us.component';
import { IonicModule } from '@ionic/angular';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { SimpleIntroComponent } from './components/simple-intro/simple-intro.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HowRentComponent } from './components/how-rent/how-rent.component';
import { WizardComponent } from './components/wizard/wizard.component';
import { TenantsAboutComponent } from './components/tenants-about/tenants-about.component';
import { IdealGuaranteeComponent } from './components/ideal-guarantee/ideal-guarantee.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { VipComponent } from './components/vip/vip.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { FilterTagComponent } from './components/properties/filter-tag/filter-tag.component';
import { FiltersComponent } from './components/properties/filters/filters.component';
import { PropertiesListComponent } from './components/properties/properties-list/properties-list.component';
import { GroupRoundButtonComponent } from './components/properties/group-round-button/group-round-button.component';
import { PricesFilterComponent } from './components/properties/prices-filter/prices-filter.component';
import { RoomsFilterComponent } from './components/properties/rooms-filter/rooms-filter.component';
import { LocationFilterComponent } from './components/properties/location-filter/location-filter.component';
import { PropertyTypeFilterComponent } from './components/properties/property-type-filter/property-type-filter.component';
import { SurfaceFilterComponent } from './components/properties/surface-filter/surface-filter.component';
import { FilterHeaderComponent } from './components/properties/filter-header/filter-header.component';
import { IntroComponent } from './components/intro/intro.component';
import { BoardComponent } from './components/board/board.component';
import { FeaturesComponent } from './components/features/features.component';
import { PropertiesComponent } from './components/properties/properties.component';
import { RequestMoreInfoComponent } from './components/request-more-info/request-more-info.component';
import { AboutComponent } from './components/about/about.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BathroomFilterComponent } from './components/properties/bathroom-filter/bathroom-filter.component';
import { ParkingFilterComponent } from './components/properties/parking-filter/parking-filter.component';
import { RouterModule } from '@angular/router';
import { GoogleReviewsComponent } from './components/google-reviews/google-reviews.component';
import { HttpClientModule } from '@angular/common/http';
import { PromoComponent } from './modals/promo/promo.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { StandAbastoComponent } from './components/stand-abasto/stand-abasto.component';
import { GoogleMapsReviewsComponent } from './components/google-maps-reviews/google-maps-reviews.component';
import { WhatsappFloatButtonComponent } from './components/whatsapp-float-button/whatsapp-float-button.component';

@NgModule({
  declarations: [
    GoogleMapsReviewsComponent,
    StandAbastoComponent,
    AboutComponent,
    BoardComponent,
    BathroomFilterComponent,
    ChooseUsComponent,
    ContactFormComponent,
    ContactFormComponent,
    FeaturesComponent,
    FilterHeaderComponent,
    FilterTagComponent,
    FiltersComponent,
    FooterComponent,
    GroupRoundButtonComponent,
    HeaderComponent,
    HowRentComponent,
    IdealGuaranteeComponent,
    IntroComponent,
    LocationFilterComponent,
    ParkingFilterComponent,
    PaymentMethodsComponent,
    PricesFilterComponent,
    PropertiesComponent,
    PropertiesListComponent,
    PropertyTypeFilterComponent,
    RequestMoreInfoComponent,
    AboutComponent,
    GoogleReviewsComponent,
    RoomsFilterComponent,
    SimpleIntroComponent,
    SurfaceFilterComponent,
    TenantsAboutComponent,
    TestimonialsComponent,
    VipComponent,
    WizardComponent,
    PromoComponent,
    ImageViewerComponent,
    WhatsappFloatButtonComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
  ],
  exports: [
    GoogleMapsReviewsComponent,
    StandAbastoComponent,
    AboutComponent,
    BoardComponent,
    BathroomFilterComponent,
    ChooseUsComponent,
    ContactFormComponent,
    ContactFormComponent,
    FeaturesComponent,
    FilterHeaderComponent,
    FilterTagComponent,
    FiltersComponent,
    FooterComponent,
    GroupRoundButtonComponent,
    HeaderComponent,
    HowRentComponent,
    IdealGuaranteeComponent,
    IntroComponent,
    LocationFilterComponent,
    ParkingFilterComponent,
    PaymentMethodsComponent,
    PricesFilterComponent,
    PropertiesComponent,
    PropertiesListComponent,
    PropertyTypeFilterComponent,
    RequestMoreInfoComponent,
    AboutComponent,
    GoogleReviewsComponent,
    RoomsFilterComponent,
    SimpleIntroComponent,
    SurfaceFilterComponent,
    TenantsAboutComponent,
    TestimonialsComponent,
    VipComponent,
    WizardComponent,
    PromoComponent,
    ImageViewerComponent,
    WhatsappFloatButtonComponent,
  ],
})
export class SharedModule {}
