import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { PreloadWizardInterface } from 'src/app/interfaces/preload-wizard.interface';

@Injectable({
  providedIn: 'root'
})
export class PreloadWizardService {

  preloadInfo = new BehaviorSubject<PreloadWizardInterface>(null);
  preLoad$ = this.preloadInfo.asObservable();

  constructor() { }

  loadPreloadInfo(infoToPreload: PreloadWizardInterface) {
    this.preloadInfo.next(infoToPreload)
  }
}
