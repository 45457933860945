import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
// import { PropertiesInterface } from 'src/app/interfaces/properties.interface';
import { PropertiesService } from 'src/app/services/properties/properties.service';

@Component({
  selector: 'app-properties-list',
  templateUrl: './properties-list.component.html',
  styleUrls: ['./properties-list.component.scss'],
})
export class PropertiesListComponent implements OnInit {

  propertiesSubcripction: Subscription;
  spinner: boolean;

  constructor(
    private router: Router,
    public propertiesService: PropertiesService
  ) {
    this.propertiesService.listGet();
  }

  ngOnInit() {}

  goPropertieDetail(id: string) {
    this.router.navigate(['/detalle-propiedad/' + id]);
  }
}
